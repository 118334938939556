<script setup lang='ts'>
import { isClient, isFunction } from '@qctsw/utils'
import { LOAD_STATE } from '@/shared/types'
import { RELOAD_CBS } from '@/composables/useLoadState'

const props = withDefaults(defineProps<{
  tagName?: string
  type?: 'up' | 'page' // up: 上拉加载更多，page: 分页加载更多
  length?: number
  state: LOAD_STATE
  errorText?: string
  reloadHandler?: () => void | Promise<void>
  // 服务端渲染失败，客户端在水合后自动重新请求
  clientAutoReload?: boolean
  commonClass?: string
  loadingClass?: string
  errorClass?: string
}>(), {
  tagName: 'div',
  type: 'page',
  length: 0,
  errorText: '数据加载失败，请重试',
  clientAutoReload: true,
  commonClass: 'min-h-20',
  loadingClass: 'text-slate-4 text-3',
  errorClass: '',
})

const nuxt = useNuxtApp()
const isServerSide = nuxt.isHydrating || !isClient
const mounted = useMounted()
const loading = computed(() => {
  return [LOAD_STATE.INIT, LOAD_STATE.LOADING].includes(props.state)
    || (isServerSide && props.clientAutoReload && props.state === LOAD_STATE.ERROR && !mounted.value)
})
// 服务端渲染失败，客户端自动重新请求
if (isClient && props.clientAutoReload && props.state === LOAD_STATE.ERROR && isFunction(props.reloadHandler)) {
  // 如果还在水合中，等待水合完成后再重新请求
  if (nuxt.isHydrating)
    // 在plugins/runtime-hooks.ts中，会在水合完成后(app:suspense:resolve)，执行RELOAD_CBS中的所有回调
    RELOAD_CBS().value.add(props.reloadHandler)
  else
    props.reloadHandler()
}
</script>

<template>
  <template v-if="loading && (type !== 'up' || (type === 'up' && length <= 0))">
    <slot name="loading">
      <component :is="tagName" class="flex justify-center items-center w-full" :class="[loadingClass, commonClass]">
        <td v-if="tagName === 'tr'">
          <i i-svg-spinners:blocks-wave />
          <span class="ml-1 pt-0.5">数据加载中...</span>
        </td>
        <template v-else>
          <i i-svg-spinners:blocks-wave />
          <span class="ml-1 pt-0.5">数据加载中...</span>
        </template>
      </component>
    </slot>
  </template>
  <template v-else-if="props.state === LOAD_STATE.ERROR">
    <slot name="error" :reload-handler="reloadHandler">
      <component :is="tagName" class="flex flex-col justify-center items-center py-4xl" :class="[errorClass, commonClass]">
        <p class="text-3 text-slate-5 mb-sm text-center">
          {{ props.errorText }}
        </p>
        <button
          v-if="reloadHandler && isFunction(reloadHandler)"
          type="button" class="btn-primary"
          @click="reloadHandler?.()"
        >
          重新加载
        </button>
      </component>
    </slot>
  </template>
  <template v-else>
    <slot />
  </template>
</template>

<style lang='scss' scoped>

</style>
